import { useLayoutEffect } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

/**
 * This hook is used to fix page scroll to top on requestFullscreen.
 */
export function useFullscreenFix() {
  const { isSSR, isEditor, isMobile } = useEnvironment();

  useLayoutEffect(() => {
    if (isSSR || isEditor) {
      return;
    }

    const $menuContainer = document.getElementById('MENU_AS_CONTAINER');

    // body has to be full page height or don't contain overflow rules ¯\_(ツ)_/¯
    document.body.style.height = 'auto';
    document.body.style.minHeight = '100%';

    // menu on mobile is relying on body height
    if (isMobile) {
      $menuContainer?.style.setProperty('--menu-height', '100vh');
    }
  }, [isSSR, isEditor, isMobile]);
}
